@font-face {
    font-family: Brandon-Light;
    src: url('assets/fonts/Brandon_light.otf') format('opentype');
    font-weight: lighter;
}

@font-face {
    font-family: Brandon-Medium;
    src: url('assets/fonts/Brandon_med.otf') format('opentype');
}

@font-face {
    font-family: Brandon-Bold;
    src: url('assets/fonts/Brandon_bld.otf') format('opentype');
    font-weight: bold;
}

* {
    box-sizing: border-box;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Brandon-Medium', Helvetica, sans-serif;

    color: #414141;
    width: 100%;
    height: 100%;
}

#start-togetherjs {
    position: fixed;
    bottom: 12px;
    right: 12px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #00a2c3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#start-togetherjs.active {
    background-color: red;
    border-bottom: none;
}

button,
.button {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 16px 24px;
    color: white;
    background: #00a2c3;
    border-radius: 6px;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

button:hover,
.button:hover {
    background: #00a3c3be;
}

.section {
    width: 100%;
    /* min-height: 100vh; */
    padding: 6em 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(0deg, #ebebeb 0%, #ffffff 100%);
}

.levelNull {
    position: absolute;
    z-index: 0;
}

.levelOne {
    position: absolute;
    z-index: 1;
}

.content {
    width: 80vw;
    max-width: 1360px;
}

/*Ipad pro 1920px x 1080px */
@media (max-width: 1366px) {
    /* html {
        font-size: 0.45vw;
    } */
    /* .section {
        min-height: 1024;
    } */
}

body {
    margin: 0;
    position: relative;
    font-size: 24px;
}

a {
    text-decoration: none;
    user-select: none;
    touch-callout: none !important;
    color: inherit;
}

a:hover {
    color: #00a2c3;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.vAlign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.row {
    align-items: center;
}

h1,
h2 {
    /* font-size: 5.8rem; */
    font-weight: bold;
    line-height: 1.1em;
    margin: 0;
    margin-bottom: 1rem;
}

h2 {
    /* font-size: 3.6rem; */
    line-height: 1.1em;
    margin-bottom: 1rem;
}

h3 {
    font-size: 36px;
    margin: 0.5em 0;
}

ul,
ol {
    font-size: 24px;
    line-height: 1.3em;
    margin: 0;
    padding: 0 1.1em;
}

p,
ul,
ol {
    font-size: 24px;
    line-height: 1.3em;
    margin: 0;
    margin-bottom: 0.6em;
}

ol {
    margin-left: -0.5rem;
}

li {
    color: #00a2c3;
}

li span {
    color: #414141;
}

.active {
    color: #00a2c3;
}

.dropDownMenue_links {
    margin: 0.3rem 0rem;
}

.header {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-size: 24px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 99999;
    top: 0;
}

.App-content {
    height: 76px;
}

#modal-root {
    position: relative;
}

.header .tab:not(:last-child) {
    margin-right: 5rem;
}

.header .link {
    cursor: pointer;
    white-space: nowrap;
}

#rbp-headerLogo {
    width: 6rem;
    margin-top: 1rem;
    fill: #00a2c3;
}

.header .link.logo {
    border-bottom: none;
}

.videoWrap {
    line-height: 0;
    box-shadow: 0px 0px 20rem 0px rgba(0, 0, 0, 0.12);
}

.dropdownMenu {
    position: relative;
}

.dropdownMenu .linkList {
    position: absolute;
    z-index: 1;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.32);
}

.dropdownMenu .linkList:after {
    content: '';
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid white;
}

.dropdownMenu .linkList .dropDownMenue_links:not(:last-child) {
    margin-bottom: 1rem;
}

/* BEGIN IPhone-Slider */
.iphoneWrap {
    width: 459px;
    height: 880px;
    position: relative;
}

.iphone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-image: url('assets/iphoneXShadow.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.slideStage {
    position: absolute;
    top: 34px;
    left: 38px;
    width: 383px;
    height: 813px;
    z-index: 2;
}

.slide {
    width: 383px;
    height: 813px;
}

.slide img {
    width: 100%;
    height: 100%;
}

.slide.special {
    position: relative;
    width: 320px;
    height: 568px;
    top: -500px;
}

.slick-next {
    right: -45px;
}

.slick-prev {
    left: -65px;
}

.slick-next:before,
.slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.35;
    color: #000;
}

.slick-dots {
    bottom: 0px;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #00a2c3;
}

@media (max-width: 1440px) {
    .iphoneWrap {
        width: 367px;
        height: 704px;
    }

    .slideStage {
        top: 24px;
        left: 29px;
        width: 310px;
        height: 655px;
    }

    .slide {
        width: 310px;
        height: 655px;
    }

    .slide.special {
        width: 256px;
        height: 454px;
        top: -400px;
    }
}

/* END IPhone-Slider

/* BEGIN Startpage */
#start {
    /* background-image: url('assets/nodes_1.svg'), url('assets/nodes_2.svg'),
        linear-gradient(-180deg, #004765 0%, #007d9e 50%, #004765 100%);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center, center, center; */

    background: rgb(1, 57, 71);
    background: radial-gradient(circle, rgba(1, 57, 71, 1) 0%, rgba(3, 81, 102, 1) 33%, rgba(0, 125, 158, 1) 100%);
    background: radial-gradient(circle, rgba(1, 57, 71, 1) 0%, #0071bc 165%, rgb(0 0 0) 100%);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 90vh;
}

#startProducts {
    background: radial-gradient(circle, rgba(1, 57, 71, 1) 0%, #0071bc 165%, rgb(0 0 0) 100%);
}

#start .imgWrap {
    width: 100%;
    height: 100%;
}

#startProducts .imageWrap {
    width: 60%;
}

#start .soccerImg {
    background-image: url('assets/soccer_1.svg');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

#start p.shortDesc {
    margin-top: 2em;
    color: white;
    text-align: center;
    /* font-size: 1em; */
    max-width: 40em;
    margin: 2em auto;
}

#start .logoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#start .rbpLogo {
    height: 70px;
    fill: white;
}

#start .title {
    margin-top: 2.4rem;
    /* font-size: 2.4rem; */
    font-weight: bold;
    color: white;
    letter-spacing: 0.05em;
}

#start .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

#start .emailForm {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    color: white;
}

#start .emailForm .innerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#start .emailForm .innerForm h3 {
    margin: 0px 0px 10px 0px;
    font-size: 24px;
}

#start .emailForm .formElements {
    display: flex;
}

#start .emailForm .innerForm input {
    height: 36px;
}

#start .emailForm input.email {
    padding: 0px 6px;
    font-size: 14px;
    width: 240px;
    border-radius: 0px;
    border: none;
}

#start .emailForm .innerForm .button {
    background: #00a2c3;
    border: none;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0px 10px;
    cursor: pointer;
    -webkit-appearance: none;
}

#start .emailForm .innerForm label {
    /* font-size: 2.5rem; */
}

#startProducts .row {
    align-items: flex-start;
}

.productList {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
}

/* END Startpage */

/* BEGIN About us  */
#aboutus-intro .logoWrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 30px;
}

#aboutus-intro .logoWrap .agidoLogo {
    width: 80%;
    padding-top: 36.65%;
    background-image: url('assets/logos/agidoLogo.svg');
    background-repeat: no-repeat;
}

#aboutus-intro .logoWrap .rbpLogo {
    width: 80%;
    padding-top: 36.65%;
    background-image: url('assets/logos/rbp-logo.svg');
    background-repeat: no-repeat;
}

#aboutus-ourRoots .factCloud {
    margin-right: -10rem;
}

#aboutus-ourRoots .factCloud .row {
    align-items: center;
}

#aboutus-ourRoots .rootsText {
    margin-left: -8rem;
    margin-top: -20rem;
}

#aboutus-ourRoots .fact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    line-height: 1em;
}

#aboutus-ourRoots .factValue {
    /* font-size: 3.6rem; */
    font-weight: bold;
}

#aboutus-ourRoots .factTitle {
    /* font-size: 3.2rem; */
    margin-top: 0.15em;
    font-weight: lighter;
}

#aboutus-ourRoots .factImg {
    width: 100%;
    padding-top: 50%;
    background-size: cover;
    /*height: 100%;
    min-height: 145px;*/
    background-repeat: no-repeat;
    background-position: center;
}

#aboutus-ourRoots .factImg.mobileImg {
    background-image: url('assets/mobile.jpg');
}

#aboutus-ourRoots .factImg.watchdogImg {
    background-image: url('assets/watchdog.jpg');
}

#aboutus-ourRoots .factImg.springImg {
    background-image: url('assets/spring.jpg');
}

#aboutus-ourRoots .factImg.cardImg {
    background-image: url('assets/card.jpg');
}

#aboutus-ourRoots .factImg.infoscreenImg {
    background-image: url('assets/infoscreen.jpg');
}

#aboutus-ourRoots .factImg.automatenImg {
    background-image: url('assets/automaten.jpg');
}

#aboutus-ourRoots .levelOne .content {
    margin-bottom: 24rem;
}

#aboutus-mission {
    background-color: black;
    background-image: url('assets/shuttle.png'), url('assets/earth2.jpg');
    background-repeat: no-repeat, no-repeat;
    background-position: right center, center bottom;
    background-size: 34rem, cover;
}

#aboutus-mission h1,
#aboutus-mission li span,
#aboutus-mission p {
    color: white;
}

#aboutus-team {
    color: white;
    text-align: center;
    background-image: url('assets/hexagons.svg'), linear-gradient(35deg, #00cff5 2%, #00a1e7 100%);
    background-blend-mode: overlay;
}

#aboutus-team .teamImage.left {
    width: 100%;
    padding-top: 150%;
    background-image: url('assets/teamLeft.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#aboutus-team .teamImage.right {
    width: 100%;
    padding-top: 150%;
    background-image: url('assets/teamRight.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#aboutus-team .teamText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30%;
    height: 100%;
}

#aboutus-experience h1 {
    color: white;
    margin-bottom: 3rem;
}

#aboutus-experience {
    color: #414141;
    text-align: center;
    background-image: url('assets/ibm.jpg');
    background-size: cover;
}

#aboutus-experience h3 {
    margin: 0;
}

#aboutus-experience .textBox {
    text-align: left;
    background: rgba(255, 255, 255, 0.8);
    padding: 2rem;
}

#aboutus-experience .textBox p {
    margin-top: 1rem;
}

#aboutus-experience .textBox:nth-child(2) {
    margin-top: 2rem;
}

/* END About us  */

/* BEGIN Platform  */
#platform-intro {
    color: white;
    text-align: center;
    background: rgb(1, 57, 71);
    /* background-image: url('assets/customer.svg'),
        radial-gradient(circle, rgba(1, 57, 71, 1) 0%, rgba(3, 81, 102, 1) 35%, rgba(0, 125, 158, 1) 100%); */
    background-image: url('assets/customer.svg'),
        radial-gradient(circle, rgba(1, 57, 71, 1) 0%, #0071bc 165%, rgb(0 0 0) 100%);
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
}

#platform-intro .row:not(:first-child) {
    margin-top: 5rem;
}

#platform-intro .row:first-child {
    /* margin-bottom: 17rem; */
}

#platform-intro .firstRight {
    padding-left: 15rem;
}

#platform-intro .secondRight {
    padding-left: 24rem;
}

#platform-intro .secondLeft {
    padding-right: 10rem;
}

#platform-intro .thirdRight {
    padding-left: 12rem;
}

#platform-intro .fourthLeft {
    padding-right: 12rem;
}

#platform-intro .fourthRight {
    padding-left: 19rem;
}

#platform-aquisition {
    background-image: linear-gradient(0deg, #ebebeb 0%, #ffffff 100%);
}

#platform-aquisition .imgWrap.steps {
    padding-top: 5rem;
}

#platform-aquisition .customerJourney {
    background-image: url('assets/customerJourney.svg');
    width: 100%;
    padding-top: 6.52%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-aquisition .imgWrap.circle {
    padding: 0rem 10rem;
    margin-top: -4rem;
}

#platform-aquisition .crm_circle {
    background-image: url('assets/crm_circle.svg');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-aquisition h1 {
    margin-bottom: 10rem;
}

#platform-bonusOfferings ul:not(.slick-dots) {
    margin-bottom: 7rem;
}

#platform-bannerSystem .imgWrap {
    width: 100%;
}

#platform-bannerSystem .bannerIframe {
    width: 300px;
    height: 250px;
    border: none;
}

#platform-bannerSystem .bannerconfig {
    background-image: url('assets/bannerconfig.png');
    width: 100%;
    padding-top: 143.74%;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#platform-bannerSystem .imgWrap.bannerdetailWrap {
    width: 70%;
}

#platform-bannerSystem .bannerdetail {
    background-image: url('assets/bannerdetail.png');
    width: 100%;
    padding-top: 88.6%;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#platform-affiliate .row.second {
    margin-top: 2rem;
}

#platform-affiliate .affiliate {
    background-image: url('assets/affiliate-program.jpg');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .row:last-child {
    margin-top: 6rem;
}

#platform-compliance .imgWrap {
    width: 100%;
    height: 100%;
}

#platform-compliance .mga {
    background-image: url('assets/mga-logo.png');
    width: 100%;
    padding-top: 19.69%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .germany {
    background-image: url('assets/germanLicenseSeal_de.svg');
    width: 100%;
    padding-top: 60%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#platform-compliance .colombia {
    background-image: url('assets/license_columbia.png');
    width: 100%;
    padding-top: 40%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .sh {
    background-image: url('assets/logo_sh.png');
    width: 100%;
    padding-top: 47.67%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .dk {
    background-image: url('assets/logo-dk.png');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .it {
    background-image: url('assets/logo_it.jpg');
    width: 100%;
    padding-top: 35.99%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-compliance .be {
    background-image: url('assets/logo_be.png');
    width: 100%;
    padding-top: 26.63%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-account {
    background-image: linear-gradient(0deg, #ebebeb 0%, #ffffff 100%);
}

#platform-payment {
    padding-top: 5rem;
}

#platform-payment .imgWrap {
    width: 100%;
    height: 100%;
    margin-top: 15rem;
}

#platform-payment .paymentboxImg {
    background-image: url('assets/paymentBox.png');
    width: 100%;
    padding-top: 100.92%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-OneClickDeposit .imgWrap {
    width: 100%;
    height: 100%;
}

#platform-OneClickDeposit .oneClick {
    background-image: url('assets/oneClick.svg');
    width: 100%;
    padding-top: 174.35%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-seamlessWallet .imgWrap {
    width: 100%;
    height: 100%;
    margin-top: 7rem;
}

#platform-seamlessWallet .seamlessWallet {
    background-image: url('assets/seamlessWallet.svg');
    width: 100%;
    padding-top: 76.14%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-fraudProtection .imgWrap {
    width: 100%;
    height: 100%;
    margin-top: 7rem;
}

#platform-fraudProtection .fraudUser {
    background-image: url('assets/fraudUser.svg');
    width: 100%;
    padding-top: 97.55%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-fraudProtection .iovationWrap {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}

#platform-fraudProtection .iovation {
    background-image: url('assets/logos/iovation.svg');
    width: 50%;
    padding-top: 9.81%;
    background-repeat: no-repeat;
    background-size: contain;
}

#platform-journal .imgWrap {
    width: 70%;
}

#platform-journal .journalImg {
    background-image: url('assets/journalScreen.png');
    width: 100%;
    padding-top: 177.5%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#platform-security .imgWrap {
    width: 100%;
}

#platform-security .security {
    background-image: url('assets/platform-security.svg');
    width: 100%;
    padding-top: 31.33%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#platform-support .freshdesk {
    background-image: url('assets/logos/freshdesk.png');
    width: 100%;
    padding-top: 31.33%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#platform-support .intercom {
    background-image: url('assets/logos/intercom.png');
    width: 100%;
    padding-top: 26%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#platform-support .logrocket {
    background-image: url('assets/logos/logRocket.png');
    width: 100%;
    padding-top: 21%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#platform-support .devicesWrap {
    width: 100%;
    margin-top: 1rem;
}

#platform-support .freshdeskDevices {
    background-image: url('assets/freshdeskDevices.png');
    width: 100%;
    padding-top: 58.17%;
    background-repeat: no-repeat;
    background-size: 100%;
}

/* END Platform  */

/* BEGIN Sportbook  */
#sportbook-responsive .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-oneClient {
    background: #f9f9f9;
}

#technology-oneClient .splitviewipad {
    background-image: url('assets/sportsbook/SplitViewIpad.jpg');
    width: 100%;
    padding-top: 73.05%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 20rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-betmatrix .imgWrap {
    width: 100%;
    height: 100%;
}

#sportbook-search .search {
    background-image: url('assets/sportsbook/Search.jpg');
    width: 100%;
    padding-top: 172.32%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-betmatrix .matrix {
    background-image: url('assets/sportsbook/Matrix.jpg');
    width: 100%;
    padding-top: 135.32%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 20rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-betslip .row {
    margin-top: 15rem;
}

#sportbook-betslip .innerRow {
    margin-top: 5rem;
}

#sportbook-liveCashout .row:nth-child(2) {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

#sportbook-liveCashout .imgWrap {
    width: 100%;
}

#sportbook-liveCashout .imgWrap:first-child {
    margin-bottom: 1rem;
}

#sportbook-liveCashout .ownBetsList {
    background-image: url('assets/sportsbook/OwnBetsList.jpg');
    width: 100%;
    padding-top: 67.56%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-liveCashout .ownBetsDetail {
    background-image: url('assets/sportsbook/OwnBetsDetail.jpg');
    width: 100%;
    padding-top: 68.63%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-liveCashout .partialCashout {
    background-image: url('assets/sportsbook/partialCashout.jpg');
    width: 100%;
    padding-top: 86.76%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#sportbook-marketAnalytics .imgWrap {
    width: 100%;
    height: 100%;
}

#sportbook-marketAnalytics .globeImg {
    background-image: url('assets/globe.svg');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#sportbook-marketAnalytics ul ~ p {
    margin-top: 2rem;
}

#sportbook-riskManagement h2 {
    margin-top: 2rem;
}

#sportbook-riskManagement .imgWrap,
#sportbook-customerProfiling .imgWrap {
    width: 100%;
}

#sportbook-riskManagement .imgWrap:last-child,
#sportbook-customerProfiling .imgWrap:last-child {
    margin-top: 5rem;
}

#pathOne,
#pathTwo,
#pathThree,
#pathFour,
#ellipse {
    stroke-dasharray: 5, 5;
    stroke-width: 5;
    stroke-dashoffset: 1000;
    animation: dash 20s linear forwards infinite;
}

#customisation-offSiteCom .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-offSiteCom .channelImg {
    background-image: url('assets/inbox.png');
    width: 100%;
    padding-top: 62.5%;
    background-repeat: no-repeat;
    background-size: 100%;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

/* END Sportbook  */

/* BEGIN Technology  */
#technology-intro {
    min-height: 90vh;
    color: white;
    text-align: center;
    background: rgb(1, 57, 71);
    background-image: url('assets/rocket.svg'),
        radial-gradient(circle, rgba(1, 57, 71, 1) 0%, rgba(3, 81, 102, 1) 35%, rgba(0, 125, 158, 1) 100%);
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
}

#technology-intro .row:not(:first-child) {
    margin-top: 5rem;
}

#technology-intro .row:first-child {
    /* margin-bottom: 17rem; */
}

#technology-intro .firstRight {
    padding-left: 15rem;
}

#technology-intro .secondRight {
    padding-left: 24rem;
}

#technology-intro .secondLeft {
    padding-right: 10rem;
}

#technology-intro .thirdRight {
    padding-left: 12rem;
}

#technology-intro .fourthLeft {
    padding-right: 12rem;
}

#technology-intro .fourthRight {
    padding-left: 19rem;
}

#technology-composeableComponents .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-composeableComponents .composeableComponentsImg {
    background-image: url('assets/composeableComponents.svg');
    width: 100%;
    padding-top: 81.46%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-beReactive {
    background-image: linear-gradient(0deg, #ffffff 0%, #ebebeb 100%);
}

#technology-beReactive .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-beReactive .row:first-child .imgWrap {
    margin-top: 5rem;
}

#technology-beReactive .architectureImg {
    background-image: url('assets/systemArchitecture.svg');
    width: 100%;
    padding-top: 70.09%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-beReactive .techLogos {
    background-image: url('assets/techLogos.png');
    width: 100%;
    padding-top: 12.38%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-beReactive .row:nth-child(2) {
    margin-bottom: -10rem;
    margin-top: 10rem;
}

#technology-seo .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-seo .row:first-child .imgWrap {
    margin-top: 5rem;
}

#technology-seo .seoImg {
    background-image: url('assets/seoOptimization.svg');
    width: 100%;
    padding-top: 36.64%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-Performance {
    background-color: black;
    background-image: url('assets/raceTrack.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

#technology-Performance li,
#technology-Performance li span {
    color: white;
}

#technology-systemArchitecture {
    background-image: linear-gradient(0deg, #ffffff 0%, #ebebeb 100%);
}

#technology-systemArchitecture .iframeWrap {
    width: 72rem;
    height: 54rem;
}

#technology-oneClient .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-oneClient .allDevicesImg {
    background-image: url('assets/Devices_All.png');
    width: 100%;
    padding-top: 64.6%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-cloudProduction {
    background: white;
    /*background-image: linear-gradient(0deg, #f2f2f2 0%, #FFFFFF 100%);*/
}

#technology-cloudProduction .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-cloudProduction .cloudInfographicImg {
    background-image: url('assets/cloud-infographic.jpg');
    width: 100%;
    padding-top: 52.95%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#technology-developerApi {
    background-image: linear-gradient(180deg, rgb(235, 235, 235) 0%, rgb(255, 255, 255) 100%);
}

#technology-developerApi .imgWrap {
    width: 100%;
    height: 100%;
}

#technology-developerApi .websocketImg {
    background-image: url('assets/websocketInterface.svg');
    width: 100%;
    padding-top: 73.89%;
    background-repeat: no-repeat;
    background-size: 100%;
}

/* END Technology  */

/* BEGIN Customisation  */
#customisation-start {
    background: #ee742b;
    color: white;
}

#customisation-start .row:first-child {
    margin-bottom: 5rem;
}

#customisation-start .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-start .cmsLogoImg {
    background-image: url('assets/cmsLogo.png');
    width: 100%;
    padding-top: 43.6%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#customisation-onsiteEditing .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-onsiteEditing .onsiteEditingImg {
    background-image: url('assets/customisation_1.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-combineContent .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-combineContent .combineContentImg {
    background-image: url('assets/customisation_2.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-manageTranslations .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-manageTranslations .manageTranslationsImg {
    background-image: url('assets/customisation_3.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-contextSensitive .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-contextSensitive .contextSensitiveImg {
    background-image: url('assets/customisation_4.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-translationStatistics .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-translationStatistics .translationStatisticsImg {
    background-image: url('assets/customisation_5.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-agileCampaign .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-agileCampaign .agileCampaignImg {
    background-image: url('assets/customisation_6.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#customisation-userManagement .imgWrap {
    width: 100%;
    height: 100%;
}

#customisation-userManagement .userManagementImg {
    background-image: url('assets/customisation_7.png');
    width: 100%;
    padding-top: 66.67%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

/* END Customisation  */
/* BEGIN Retail  */
#retail-customerCard .imgWrap {
    width: 100%;
}

#retail-customerCard .imgWrap:not(:first-child) {
    margin-top: 5rem;
}

#retail-customerCard .nfcLogo {
    background-image: url('assets/customer_card_1.png');
    width: 100%;
    padding-top: 63.19%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#retail-customerCard .qrCode {
    background-image: url('assets/qrcode.png');
    width: 50%;
    padding-top: 50%;
    margin-left: 25%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#retail-customerCard .walletLogo {
    background-image: url('assets/customer_card_2.png');
    width: 100%;
    padding-top: 43.72%;
    background-repeat: no-repeat;
    background-size: 100%;
}

#retail-terminals {
    background: #000000;
}

#retail-terminals h1,
#retail-terminals p {
    color: #ffffff;
}

#retail-terminals .imgWrap {
    width: 100%;
    height: 100%;
}

#retail-terminals .terminalsImg {
    background-image: url('assets/selfServiceTerminals.png');
    width: 100%;
    padding-top: 63.19%;
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: 0px 0px 15rem 0px rgba(0, 0, 0, 0.12);
}

#retail-cashAndCards {
    background: #f9f9f9;
}

#retail-cashAndCards .imgWrap {
    width: 100%;
    height: 100%;
}

#retail-cashAndCards .atmImg {
    background-image: url('assets/atm.jpg');
    width: 100%;
    padding-top: 185.47%;
    background-repeat: no-repeat;
    background-size: 100%;
}

/* END Retail  */

#service-dataAnalysis {
    min-height: 100vh;
    color: white;
    background-color: black;
    background-image: url('assets/stahl_1.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

#service-dataAnalysis li span {
    color: white;
}

#casino {
    min-height: 60vh;
    color: white;
    text-align: center;
    background: rgb(1, 57, 71);
    background-image: url('assets/casino.svg'),
        radial-gradient(circle, rgba(1, 57, 71, 1) 0%, #0071bc 165%, rgb(0 0 0) 100%);
    background-image: radial-gradient(circle, rgba(1, 57, 71, 1) 0%, #0071bc 165%, rgb(0 0 0) 100%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: left;
}

#casino li span {
    color: white;
}

#casino .casinoImg {
    background-image: url('assets/casino.svg');
    width: 100%;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.casinoTechnology {
    background-image: url('assets/casino_start.jpg');
    width: 100%;
    padding-top: 74%;
    background-repeat: no-repeat;
    background-size: contain;
}

.casinoLobby {
    background-image: url('assets/casino_LobbyMgnt.png');
    width: 100%;
    padding-top: 58%;
    background-repeat: no-repeat;
    background-size: contain;
}

.casinoGameManagement {
    background-image: url('assets/casino_GameMgnt.png');
    width: 100%;
    padding-top: 58%;
    background-repeat: no-repeat;
    background-size: contain;
}

.casinoReliability {
    background-image: url('assets/casino_Transactions.png');
    width: 100%;
    padding-top: 58%;
    background-repeat: no-repeat;
    background-size: contain;
}

/* BEGIN Contact Us */
#contactus {
    min-height: 100vh;
}

#contactus .top {
    align-items: flex-start;
}

#contactus .impressum {
    justify-content: center;
    display: flex;
}

#contactus .wrapper {
    display: inline-block;
}

#contactus .googlemap {
    width: 100%;
    aspect-ratio: 1/1;
    background-image: url('assets/googlemap.png');
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}

/* END Contact Us */